import { createAction, props } from '@ngrx/store';

export namespace FeatureFlagActions {
  export const loadFeatureFlags = createAction(
    '[FEATURE FLAG] LOAD_FEATURE_FLAGS',
  );

  export const loadShowMessageSuccess = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_SUCCESS',
    props<{ payload: boolean }>(),
  );
  export const loadShowMessageError = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_ERROR',
  );

  export const loadIsNewDetailScreenEnabledSuccess = createAction(
    '[FEATURE FLAG] LOAD_IS_NEW_DETAIL_SCREEN_ENABLED_SUCCESS',
    props<{ payload: boolean }>(),
  );

  export const loadIsNewDetailScreenEnabledError = createAction(
    '[FEATURE FLAG] LOAD_IS_NEW_DETAIL_SCREEN_ENABLED_ERROR',
  );
}
