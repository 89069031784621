import { createFeature, createReducer, on } from '@ngrx/store';
import { FeatureFlagActions } from './feature-flag.actions';

export const featureFlagKey = 'featureFlag';

export interface FeatureFlagState {
  isInfoMessageShown: boolean;
  isNewDetailScreenEnabled: boolean;
}

export const initialFeatureFlagState: FeatureFlagState = {
  isInfoMessageShown: false,
  isNewDetailScreenEnabled: false,
};

const _featureFlag = createReducer(
  initialFeatureFlagState,

  on(FeatureFlagActions.loadShowMessageSuccess, (state, { payload }) => ({
    ...state,
    isInfoMessageShown: payload,
  })),
  on(FeatureFlagActions.loadShowMessageError, (state) => ({
    ...state,
    isInfoMessageShown: false,
  })),
  on(
    FeatureFlagActions.loadIsNewDetailScreenEnabledSuccess,
    (state, { payload }) => ({
      ...state,
      isNewDetailScreenEnabled: payload,
    }),
  ),
  on(FeatureFlagActions.loadIsNewDetailScreenEnabledError, (state) => ({
    ...state,
    isNewDetailScreenEnabled: false,
  })),
);

export const FeatureFlagFeature = createFeature({
  name: featureFlagKey,
  reducer: _featureFlag,
});
