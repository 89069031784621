import { createFeature, createReducer, on } from '@ngrx/store';
import { ConnectedActions } from './connected.actions';

export const connectedKey = 'shared-fleet-monitor';

export interface ConnectedState {
  isOperatorMonitor: boolean;
}

export const initialConnectedState: ConnectedState = {
  isOperatorMonitor: false,
};

const _connectedReducer = createReducer(
  initialConnectedState,
  on(ConnectedActions.SetIsOperatorMonitor, (state, { isOperatorMonitor }) => ({
    ...state,
    isOperatorMonitor,
  })),
);

export const ConnectedFeature = createFeature({
  name: connectedKey,
  reducer: _connectedReducer,
});
